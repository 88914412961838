<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main" v-if="[1,4].includes(dateType)">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<div class="filter-main" v-else>{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">1{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleDatePicker(4)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,4)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.flow.periodScatter')}}</div>
				<!-- <div class="barbox">
					<div class="item-link"><span>{{$t('sycm.actions.download')}}</span><i class="el-icon-download"></i></div>
				</div> -->
			</div>
			<div class="section-main">
				<div class="period-chart">
					<period-chart id="item1" v-if="periodChart" :x-data="periodChart.xData" :s-data="periodChart.sData" :max-axis="periodChart.max.toString()" legend-left="0" height="100%" width="100%" />
				</div>
				<div class="period-desc">
					<div class="desc-icon"></div>
					<div class="desc-main">
						<div class="title">{{$t('sycm.flow.periodScatter1')}}</div>
						<!-- <div class="text">{{$t('sycm.flow.periodScatter2')}}<span class="item-time">02:00~02:59</span>(1人)</div> -->
						<div class="text">{{$t('sycm.flow.periodScatter3')}}<a :href="$workBasePath+'/home/goods/sell-manage/all?type=on_sale'" target="_bank" class="item-link">{{$t('sycm.actions.babyUpperLower')}}>></a></div>
					</div>
				</div>
			</div>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div>
					<div class="title">{{$t('sycm.common.sourceScatter')}}</div>
					<ul class="head-menu">
						<li v-for="(item,index) in $t('sycm.options.flowSource')" :key="index" :class="{active:activeTab==index+1}" @click="handleMenu(index+1)">{{item}}</li>
					</ul>
				</div>
				<!-- <div class="barbox">
					<span>{{$t('sycm.actions.download')}}<i class="el-icon-download"></i></span>
				</div> -->
			</div>
			<div class="section-main">
				<div class="sycm-map">
					<div class="map-chart">
						<map-chart id="map-chart" v-if="mapData" :map-data="mapData" :map-name="activeTab == 2 ? $t('sycm.flow.buyersPlaced') : $t('sycm.common.visitor')" :visual-max="mapMax" height="100%" width="100%" />
					</div>
					<div class="map-rank">
						<table class="rank-table" style="width: 100%">
							<tr>
								<th width="50px">{{$t('sycm.tableHead.region')}}</th>
								<th width="65px"></th>
								<th width="155px" v-if="activeTab!==2">{{$t('sycm.common.visitor')}}</th>
								<th width="155px" v-else>{{$t('sycm.flow.buyersPlaced')}}</th>
								<th width="100px" align="right">{{$t('sycm.tableHead.orderConversion')}}</th>
							</tr>
							<tr v-for="(item,index) in sourceData" :key="index">
								<td align="center">{{item.province_name}}</td>
								<template v-if="activeTab!==2">
									<td align="right">{{item.visitors}}</td>
									<td><div class="rank-bar" :class="index | colorFileter()" :style="'width: '+(item.visitors/visitorsMax*100)+'%'"></div></td>
									<td align="right">{{ item.rate | priceFilter() }}%</td>
								</template>
								<template v-else>
									<td align="right">{{item.buyers}}</td>
									<td><div class="rank-bar" :class="index | colorFileter()" :style="'width: '+(item.buyers/buyersMax*100)+'%'"></div></td>
									<td align="right">{{ item.rate | priceFilter() }}%</td>
								</template>
							</tr>
						</table>
						<div v-if="!sourceData.length>0" class="rank-empty">{{$t('sycm.empty.rank')}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.flow.behaviorScatter')}}</div>
				<div class="barbox"></div>
			</div>
			<div class="section-main">
				<div class="sycm-behavior">
					<!-- <div class="item">
						<div class="item-name">
							<span>{{$t('sycm.flow.sourceKeywords')}}TOP5</span>
							<el-popover
								placement="bottom-start"
								width="477"
								trigger="hover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-text">{{$t('sycm.popover.sourceKeywords1')}}</div>
							</el-popover>
						</div>
						<div class="item-main">
							<table style="width: 100%">
								<tr>
									<th align="left" width="20%">{{$t('sycm.tableHead.keywords')}}</th>
									<th align="right" width="20%"></th>
									<th align="right" width="20%"></th>
									<th align="right" width="20%">{{$t('sycm.tableHead.proportion')}}</th>
									<th align="right" width="20%">{{$t('sycm.tableHead.orderConversion')}}</th>
								</tr>
								<tr>
									<td>白色t恤短...</td>
									<td align="right">1</td>
									<td><div class="item-bar"></div></td>
									<td align="right">100.00%</td>
									<td align="right">0.00%</td>
								</tr>
							</table>
						</div>
					</div> -->
					<div class="item">
						<div class="item-name">
							<span>{{$t('sycm.flow.viewsScatter')}}</span>
							<el-popover
								placement="bottom-start"
								width="477"
								trigger="hover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-text">{{$t('sycm.popover.viewsScatter1')}}</div>
							</el-popover>
						</div>
						<div class="item-main">
							<table style="width: 100%">
								<tr>
									<th align="left" width="20%">{{$t('sycm.common.views')}}</th>
									<th align="right" width="20%">{{$t('sycm.common.visitor')}}</th>
									<th align="right" width="20%"></th>
									<th align="right" width="20%">{{$t('sycm.tableHead.proportion')}}</th>
									<th align="right" width="20%"></th>
								</tr>
								<tr v-for="(item,index) in behaviorData" :key="index">
									<td>{{item.views}}</td>
									<td align="right">{{item.visitors}}</td>
									<td><div class="item-bar"><div class="bar-box" :style="'width:'+(item.visitors/maxVisitors*100)+'%'"></div></div></td>
									<td align="right">{{item.rate}}%</td>
									<td align="right"></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import periodChart from '@/components/echart/ipollChart'
import mapChart from '@/components/echart/mapChart'
import { fetchFlowPeriod, fetchFlowRegion, fetchFlowBehavior } from "@/api/sycm"

export default {
	components: { periodChart, mapChart },
	filters: {
		colorFileter(type) {
			const options = ['blue', 'yellow', 'orange']
			return options[type]
		}	
	},
	data() {
		return {
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			startTime: '',
			endTime: '',
			periodChart: null,
			activeTab: 1,
			sourceData: [],
			mapData: null,
			visitorsMax: 0,
			buyersMax: 0,
			mapMax: 500,
			behaviorData: [],
			maxVisitors: 0
		}
	},
	created () {
		this.handleChangeTime()
	},
	methods: {
		async getData() {
			this.periodChart = null
			this.mapData = null
			const loading = this.$loading()
			try {
				const params = {
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType
				}
				const { data } = await fetchFlowPeriod(params)
				this.periodChart = this.getChartData(data.list)

				this.getSourceList()

				const behavior = await fetchFlowBehavior(params)
				this.behaviorData = behavior.data.list
				var maxVisitors = 0
				this.behaviorData.forEach(item => {
					if(item.visitors>maxVisitors) {
						maxVisitors = item.visitors
					}
				})
				this.maxVisitors = maxVisitors
			} catch(error) {
				console.log(error)
			}
			loading.close()
		},
		getChartData(data) {
			const xData = []
			const todayData = []
			const yesterdayData = []
			var max = 0
			data.forEach(item=> {
				xData.push(item.x_values+':00')
				todayData.push(item.values)
				yesterdayData.push(item.comp_values)
				if(item.values > max) {
					max = item.values
				}
				if(item.comp_values > max) {
					max = item.comp_values
				}
			})
			const option = {
				max: max ? max : 4,
				xData: xData,
				sData: [{
					name: this.$t('sycm.common.visitor'),
					data: todayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				},{
					name: this.$t('sycm.flow.buyersPlaced'),
					data: yesterdayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					z: 4,
					itemStyle: {
						color: '#f3d024'
					}
				}]
			}
			return option
		},
		handleChangeTime(val) {
			this.dateType = val ? val : 1
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			this.$refs.dayPicker.focus()
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
			this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
			this.getData()
		},
		async getSourceList() {
			const params = {
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000),
				date_type: this.dateType,
				type: this.activeTab
			}
			const { data } = await fetchFlowRegion(params)
			this.sourceData = data.list
			const mapData = []
			let visitorsMax = 0;
			let buyersMax = 0;
			data.list.forEach(item => {
				if(this.activeTab !== 1){
					mapData.push({name: item.province_name,value: item.buyers})
				}else {
					mapData.push({name: item.province_name,value: item.visitors})
				}
				if(item.visitors>visitorsMax) {
					visitorsMax = item.visitors
				}
				if(item.buyers>buyersMax) {
					buyersMax = item.buyers
				}
			});
			this.visitorsMax = visitorsMax
			this.buyersMax = buyersMax
			const max = this.activeTab !== 1 ? buyersMax : visitorsMax
			this.mapMax = max > 0 ? max : 500
			this.$nextTick(()=>{
				this.mapData = mapData
			})
		},
		handleMenu(val) {
			this.mapData = null
			this.activeTab = val
			this.getSourceList()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				.item-link {
					color: $--sycm-primary;
					cursor: pointer;
					display: inline-block;

					span {
						margin-right: 5px;
					}

					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.period-chart {
		height: 300px;
		margin-top: 15px;
	}
	.period-desc {
		display: flex;
		padding-top: 10px;
		.desc-icon {
			flex-shrink: 0;
			width: 70px;
			height: 98px;
			background-image: url('~@/assets/images/sycm/ic-period-70-71.png');
			background-repeat: no-repeat;
			background-size: 100% auto;
			margin-right: 28px;
			margin-top: 8px;
		}
		.desc-main {
			flex: 1;
			width: 0;
			font-size: 12px;
			line-height: 1.5;
			.title {
				color: #606060;
				font-weight: 500;
				font-size: 16px;
				margin: 8px 0;
			}
			.item-time {
				color: #ff9000;
				margin: 0 6px;
			}
			.item-link {
				color: $--sycm-primary;
			}
		}
	}
	.sycm-map {
		display: flex;
		justify-content: space-between;
		.map-chart {
			width: 500px;
			height: 375px;
		}
		.map-rank {
			width: 370px;
			padding-top: 30px;
			font-size: 12px;
			line-height: 1.5;
			.rank-table {
				th {
					color: $--sycm-primary;
				}
				th,td {
					padding: 4px;
				}
			}
			.rank-empty {
				height: 280px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.rank-bar {
				height: 16px;
				width: 100%;
				background-color: #4cb5ff;
				&.blue {
					background-color: $--sycm-primary;
				}
				&.yellow {
					background-color: #f3d024;
				}
				&.orange {
					background-color: #ff8533;
				}
			}
		}
	}
	.sycm-behavior {
		display: flex;
		.item {
			flex-shrink: 0;
			width: 50%;
			padding: 20px 20px 0;
			box-sizing: border-box;
			.item-name {
				font-size: 14px;
				line-height: 22px;
				.el-popover__reference {
					color: #999999;
				}
			}
			.item-main {
				font-size: 12px;
				line-height: 1.5;
				table {
					th,td {
						padding: 6px;
					}
					th {
						font-weight: normal;
						color: #999999;
					}
				}
			}
			.item-bar {
				height: 6px;
				width: 100%;
				background-color: rgba(0,0,0,.04);
				.bar-box {
					height: 6px;
					width: 100%;
					background-color: $--sycm-primary;
				}
			}
		}
	}
}
</style>